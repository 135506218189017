<template>
  <div>
    <v-card-title>
      <v-row>
        <v-col cols="3">
          <div class="d-flex">
            <v-text-field class="mt-0 pt-0" v-model="filters.search" append-icon="mdi-magnify" label="Search" single-line clearable hide-details style="max-width: 300px" />
            <v-btn class="mt-0 pt-0" @click="showFilters = !showFilters" icon>
              <v-icon>{{ !showFilters ? "mdi-filter-variant" : "mdi-filter-variant-remove" }}</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="9" v-if="showFilters">
          <v-row>
            <v-col cols="2">
              <v-text-field class="mt-0 pt-0" v-model="filters.unitId" label="Unit ID" single-line clearable hide-details style="max-width: 300px" />
            </v-col>
            <v-col cols="2">
              <v-text-field class="mt-0 pt-0" v-model="filters.carrierOut" label="Carrier Out" single-line clearable hide-details style="max-width: 300px" />
            </v-col>
            <v-col cols="2">
              <v-text-field class="mt-0 pt-0" v-model="filters.serviceOut" label="Service Out" single-line clearable hide-details style="max-width: 300px" />
            </v-col>
            <v-col cols="2">
              <v-text-field class="mt-0 pt-0" v-model="filters.areaScarico" label="Area" single-line clearable hide-details style="max-width: 300px" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>
    <div class="text-right"></div>
    <v-card-actions>
      <v-spacer />
      <CSVExporter :items="exportData" /> 
      <v-btn 
        title="Esportazione CSV gruista" 
        icon="icon"
        style="margin-right: 10px;"
        @click="dialogCSVgruista = true"  
      >
        <v-icon color="success" medium>mdi-crane</v-icon>
      </v-btn>
      <v-btn color="primary" @click="onOpenMassiveOperations" :disabled="disabled" medium>Operazioni massive</v-btn>
      <v-btn color="success" @click="onSave" :disabled="saveDisabled" medium>Salva</v-btn>
    </v-card-actions>
    <div class="mb-2 subtitle-1">
      <b class="mr-3" v-if="carriSet != null">Carri: {{ carriSet.size }}</b>
      <b v-if="utiSet != null"> Uti: {{ utiSet.size }}</b>
    </div>
    <v-data-table
      dense
      :headers="headers"
      :search="filters.search"
      :items="utiListMap"
      @current-items="setFilteredUti"
      :items-per-page="10000"
      hide-default-footer
      class="elevation-1"
      :custom-filter="globalSearch"
    >
      <template v-slot:item.targa="{ item }">
        {{ item.targa === "0" ? "ND" : item.targa }}
      </template>

      <template v-slot:visitaUti.uti.pericoloso="{ item }">
        <v-icon v-if="item.visitaUti.uti.pericoloso" class="uti-danger-icon">mdi-alert</v-icon>
      </template>
      <template v-slot:item.viaggioIn="{ item }">
        <span v-if="item.viaggioIn && item.viaggioIn.nodoPartenza">
          {{ item.viaggioIn.nodoPartenza.descrizione }}
        </span>
      </template>
      <template v-slot:item.remainOnBoardScarico="{ item }">
        <v-checkbox v-model="item.remainOnBoardScarico" :disabled="disabled" @change="markModified(item)" />
      </template>
      <template v-slot:item.areaScarico="{ item }">
        <v-text-field
          :label="item.remainOnBoardScarico ? '' : 'Assegna area'"
          v-model="item.areaScarico"
          :disabled="disabled || item.remainOnBoardScarico"
          @input="markModified(item)"
          single-line
          hide-details
        >
        </v-text-field>
      </template>
      <template v-slot:item.noteScarico="{ item }">
        <v-text-field
          :label="item.remainOnBoardScarico ? '' : 'Assegna note'"
          v-model="item.noteScarico"
          :disabled="disabled || item.remainOnBoardScarico"
          @input="markModified(item)"
          single-line
          hide-details
        />
      </template>
    </v-data-table>
    <v-dialog v-model="operazioniMassiveVisible" persistent width="600">
      <v-card>
        <v-card-title>Operazioni massive</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-checkbox v-model="remainOnBoardMassive" @click="deleteAreaNote()" :disabled="deleteAreaMassive" label="Remain on Board" />
            </v-col>
            <v-col>
              <v-checkbox v-model="deleteAreaMassive" @click="deleteAreaNote()" :disabled="remainOnBoardMassive && remainOnBoardMassive" label="Cancella Area Massiva" />
            </v-col>
          </v-row>
          <v-text-field
            v-model="areaMassive"
            :disabled="remainOnBoardMassive || deleteAreaMassive"
            :label="remainOnBoardMassive || deleteAreaMassive ? '' : 'Assegna area massivamente'"
          />
          <v-text-field
            v-model="noteMassive"
            :disabled="remainOnBoardMassive || deleteAreaMassive"
            :label="remainOnBoardMassive || deleteAreaMassive ? '' : 'Assegna note massivamente'"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="assignMassive" :disabled="btnAssignMassiveDisabled" text small>Applica a tutte UTI filtrate</v-btn>
          <v-btn @click="operazioniMassiveVisible = false" text small>annulla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCSVgruista" width="600">
      <v-card>
        <v-card-title>Esportazione CSV gruista</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row>
                <v-col class="directions">{{ invertOrder ? nomeDirUscita : nomeDirEntrata }}</v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-row v-for="uti in sampleUtiList" :key="uti" no-gutters>
                    <v-col class="sample-uti">{{ uti }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="directions">{{ invertOrder ? nomeDirEntrata : nomeDirUscita }}</v-col>
              </v-row>
            </v-col>
            <v-col class="invert-btn-column">
              <v-btn small style="border-radius: 50px;" @click="invertOrder = !invertOrder">
                <v-icon medium :style="invertOrder ? 'rotate: 180deg;' : ''">mdi-compare-vertical</v-icon>
                Inverti ordine
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="exportCSVgruista" color="secondary">Scarica</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ToastMixin from "../../../mixins/ToastMixin";
import CSVExporter from "../../anagrafiche/CSVExporter.vue";
export default {
  name: "ExportUtiList",
  components: { CSVExporter },
  mixins: [ToastMixin],
  props: {
    convoglio: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    viaggioId: null,
  },
  data() {
    return {
      remainOnBoardMassive: null,
      deleteAreaMassive: false,
      areaMassive: null,
      noteMassive: null,
      filteredUti: [],
      operazioniMassiveVisible: false,
      dialogCSVgruista: false,
      invertOrder: false,
      saveDisabled: true,
      utiSet: null,
      carriSet: null,
      nomeDirEntrata: null,
      nomeDirUscita: null,
      showFilters: false,
      filters: {
        search: "",
        unitId: "",
        carrierOut: "",
        serviceOut: "",
        areaScarico: "",
      },
    };
  },
  computed: {
    utiListMap() {
      // this.utiSet = new Set();
      // this.carriSet = new Set();

      return this._.transform(
        this.convoglio.listCarri,
        (result, cc) => {
          if (cc.listUti) {
            var filteredListUti = cc.listUti.filter((utiCarro) => utiCarro.tipoMovimento === "EXPORT");
            filteredListUti.forEach((utiCarro) => {
              utiCarro.viaggioIn = cc.viaggioIn;
              utiCarro.viaggioOut = cc.viaggioOut;
              utiCarro.targa = cc.visitaCarro.targa;
              result.push(utiCarro);
              // this.utiSet.add(utiCarro.visitaUti.id);
              // this.carriSet.add(cc.visitaCarro.targa);
            });
          }
          return result;
        },
        []
      );
    },
    headers() {
      return [
        {
          text: "Carro",
          value: "targa",
          align: "center",
          class: "header text-uppercase",
        },
        {
          text: "Cat.",
          value: "visitaUti.uti.pericoloso",
          align: "center",
          class: "header text-uppercase",
        },
        {
          text: "Tipo",
          value: "visitaUti.tipoUti.codice",
          align: "center",
          class: "header text-uppercase",
        },
        {
          text: "Unit ID",
          value: "visitaUti.uti.codice",
          align: "center",
          class: "header text-uppercase",
          filter: this.unitIdFilter,
        },
        {
          text: "Peso",
          value: "visitaUti.pesoLordo",
          align: "center",
          class: "header text-uppercase",
        },
        {
          text: "Lunghezza",
          value: "visitaUti.uti.lunghezza",
          align: "center",
          class: "header text-uppercase",
        },
        {
          text: "Posizione",
          value: "posizioneCarroConvoglio",
          align: "center",
          class: "header text-uppercase",
        },
        {
          text: "Stato",
          value: "stato",
          align: "center",
          class: "header text-uppercase",
        },
        {
          text: "Provenienza",
          value: "viaggioIn",
          sortable: false,
          filterable: false,
          align: "center",
          class: "header text-uppercase",
        },
        {
          text: "Service out",
          value: "visitaUti.serviceOut",
          align: "center",
          class: "header text-uppercase",
          filter: this.serviceOutFilter,
        },
        {
          text: "Operator",
          value: "operator",
          align: "left",
          class: "header text-uppercase",
          filter: this.operatorFilter,
        },
        {
          text: "Carrier out",
          value: "visitaUti.carrierOut",
          align: "center",
          class: "header text-uppercase",
          filter: this.carrierOutFilter,
        },
        {
          text: "Remain on Board",
          value: "remainOnBoardScarico",
          align: "center",
          class: "header text-uppercase",
        },
        {
          text: "Area",
          value: "areaScarico",
          align: "center",
          class: "header text-uppercase",
          filter: this.areasFilter,
        },
        {
          text: "Note",
          value: "noteScarico",
          align: "center",
          class: "header text-uppercase",
        },
      ];
    },
    btnAssignMassiveDisabled() {
      if (!this.deleteAreaMassive && (!this.remainOnBoardMassive || this.remainOnBoardMassive === null) && !this.areaMassive && !this.noteMassive) {
        return true;
      } else if (
        !this.deleteAreaMassive &&
        (!this.remainOnBoardMassive || this.remainOnBoardMassive === null) &&
        this.areaMassive &&
        this.areaMassive.length <= 0 &&
        this.areaMassive &&
        this.noteMassive.length <= 0
      ) {
        return true;
      }
      return false;
    },
    exportData() {
      const data = [];
      this.utiListMap.forEach((row) => {
        data.push({
          carro: row.targa,
          cat: "",
          tipo: row.visitaUti.tipoUti.codice,
          unitId: row.utiCodice,
          peso: row.visitaUti.pesoLordo,
          lunghezza: row.visitaUti.tipoUti.lunghezzaEsterna,
          posizione: row.posizioneCarroConvoglio,
          stato: row.stato,
          provenienza: row.viaggioIn.nodoPartenza.codice,
          serviceOut: row.visitaUti.serviceOut,
          carrierOut: row.visitaUti.carrierOut,
          remainOnBoard: row.remainOnBoardScarico,
          area: row.areaScarico,
          note: row.noteScarico,
        });
      });
      return data;
    },
    sampleUtiList() {
      let ret = this.exportData.map(u => u.unitId);
      ret = this.invertOrder ? ret.reverse() : ret;
      if (ret.length > 4) {
        ret = ret.slice(0,4);
        ret.push("...");
      }

      return ret;
    }
  },
  async mounted() {
    window.onbeforeunload = this.onbeforeunloadWindow();
    const nodoDef = await this.$api.trainData.getNodoDefault();
    this.nomeDirEntrata = nodoDef[0] || "Entrata";
    this.nomeDirUscita = nodoDef[1] || "Uscita";
  },
  methods: {
    areasFilter(value) {
      if (!this.filters.areaScarico) {
        return true;
      }
      return value === this.filters.areaScarico;
    },
    unitIdFilter(value) {
      if (!this.filters.unitId) {
        return true;
      }
      return value === this.filters.unitId;
    },
    carrierOutFilter(value) {
      if (!this.filters.carrierOut) {
        return true;
      }
      return value === this.filters.carrierOut;
    },
    serviceOutFilter(value) {
      if (!this.filters.serviceOut) {
        return true;
      }
      return value === this.filters.serviceOut;
    },
    operatorFilter(value) {
      if (!this.filters.operator) {
        return true;
      }
      return value === this.filters.operator;
    },
    globalSearch(value, search, item) {
      let checkUtiCodice = item.utiCodice && item.utiCodice.toLowerCase().includes(search.toLowerCase());
      //console.log("UtiCodice: " + item.utiCodice + " checkUtiCodice: " + checkUtiCodice);

      let checkTarga = item.targa && item.targa.toLowerCase().includes(search.toLowerCase());
      //console.log("Targa: " + item.targa + " checkTarga: " + checkTarga);

      let checkCarrierOut = item.visitaUti.carrierOut && item.visitaUti.carrierOut.toLowerCase().includes(search.toLowerCase());
      //console.log("CarrierOut: " + item.visitaUti.carrierOut + " checkCarrierOut: " + checkCarrierOut);

      let checkServiceOut = item.visitaUti.serviceOut && item.visitaUti.serviceOut.toLowerCase().includes(search.toLowerCase());
      //console.log("ServiceOut: " + item.visitaUti.serviceOut + " checkServiceOut: " + checkServiceOut);

      let checkAreaScarico = item.areaScarico && item.areaScarico.toLowerCase().includes(search.toLowerCase());
      //console.log("AreaScarico: " + item.areaScarico + " checkTarga: " + checkAreaScarico);

      let checkNoteScarico = item.noteScarico && item.noteScarico.toLowerCase().includes(search.toLowerCase());
      //console.log("NoteScarico: " + item.noteScarico + " checkNoteScarico: " + checkNoteScarico);

      let checkStato = item.stato && item.stato.toLowerCase().includes(search.toLowerCase());
      //console.log("Stato: " + item.stato + " checkStato: " + checkStato);

      let checkTipoUti = item.visitaUti.tipoUti.codice && item.visitaUti.tipoUti.codice.toLowerCase().includes(search.toLowerCase());
      //console.log("TipoUti: " + item.visitaUti.tipoUti.codice + " checkTipoUti: " + checkStato);

      let checkPartenza = item.viaggioIn.nodoPartenza.codice && item.viaggioIn.nodoPartenza.codice.toLowerCase().includes(search.toLowerCase());
      //console.log("Partenza: " + item.viaggioIn.nodoPartenza.codice + " checkPartenza: " + checkPartenza);

      let check = checkUtiCodice || checkTarga || checkCarrierOut || checkServiceOut || checkAreaScarico || checkNoteScarico || checkStato || checkTipoUti || checkPartenza;
      //console.log("Check: " + check);

      return check;
    },
    assignMassive: function () {
      this.filteredUti.forEach((uti) => {
        let itemIndex = this.utiListMap.indexOf(uti);
        let editedItem = this.utiListMap[itemIndex];
        editedItem.areaScarico = this.areaMassive;
        editedItem.noteScarico = this.noteMassive;
        editedItem.remainOnBoardScarico = this.remainOnBoardMassive;
        this.markModified(editedItem);
        this.utiListMap.splice(itemIndex, 1, editedItem);
      });
      this.operazioniMassiveVisible = false;
      this.saveDisabled = false;
    },
    onOpenMassiveOperations: function () {
      this.deleteAreaMassive = false;
      this.remainOnBoardMassive = null;
      this.areaMassive = null;
      this.noteMassive = null;
      this.operazioniMassiveVisible = true;
      this.saveDisabled = true;
    },
    deleteAreaNote() {
      this.areaMassive = null;
      this.noteMassive = null;
    },
    onSave: function () {
      const data = [];
      this.utiListMap.forEach((row) => {
        if (row.modified) {
          data.push({
            id: row.id,
            area: row.areaScarico,
            note: row.noteScarico,
            remainOnBoard: row.remainOnBoardScarico,
          });
        }
      });
      this.updateUtiCarroList(data);
    },
    async updateUtiCarroList(data) {
      try {
        await this.$api.trainData.updateUtiCarroList(data);
        this.saveDisabled = true;
        this.showSuccess("UTI salvate correttamente");
      } catch (e) {
        console.log("ERRORE Update UTI", e);
        this.showError("Salvataggio UTI non andato a buon fine");
      }
    },
    markModified: function (utiListMapItem) {
      utiListMapItem.modified = true;
      this.saveDisabled = false;
      if (utiListMapItem.remainOnBoard) {
        utiListMapItem.area = null;
        utiListMapItem.note = null;
      }
    },
    setFilteredUti(filteredUti) {
      this.filteredUti = filteredUti;
      // console.log("filteredUti",this.filteredUti)
      this.utiSet = new Set();
      this.carriSet = new Set();
      filteredUti.forEach((u) => {
        this.utiSet.add(u.visitaUti.id);
        if (u.targa !== "0") {
          this.carriSet.add(u.targa);
        }
      });
    },
    onbeforeunloadWindow: function () {
      //TODO NON VA
      console.log("onbeforeunloadWindow");
      if (this.saveDisabled) {
        return null;
      }
      const answer = window.confirm("Do you really want to leave? you have unsaved changes!");
      return answer ? null : true;
    },
    exportCSVgruista() {
      const columnLabels = ["Contenitore", "ISO (tipo)", "Posizione", "Peso (in Kg)", "Area"]
      let content = this.exportData.map(item => {
        var columns = ["unitId", "tipo", "posizione", "peso", "area"];
        const ret = [];
        columns.forEach( c => ret.push(item[c] || ''));

        return ret.join(";");
      });
      content = this.invertOrder ? content.reverse() : content;
      
      const data = encodeURI("data:text/csv;charset=utf-8," +
        [
          columnLabels.join(";"),
          ...content
        ].join("\n").replace(/(^\[)|(\]$)/gm, "")
      );
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "exportGruista.csv");
      link.click();

      this.dialogCSVgruista = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    //TODO NON VA
    console.log("beforeRouteLeave", to);
    if (this.saveDisabled) {
      return next();
    }
    const answer = window.confirm("Do you really want to leave? you have unsaved changes!");
    if (answer) next();
    else next(false);
  },
  watch: {
    dialogCSVgruista(value) {
      this.invertOrder = value && this.invertOrder;
    }
  }
};
</script>

<style scoped>
.bottomSave {
  display: block;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 100;
  font-weight: bold;
}
.uti-danger-icon {
  color: red;
}
.uti-import-icon {
  color: #00ffff;
}
.uti-export-icon {
  color: #36ff8f;
}
.directions {
  font-size: 1rem; 
   font-weight: bold;
}
.sample-uti {
  display: flex; 
  justify-content: center;
  font-weight: 500;
}
.invert-btn-column {
  display: flex; 
  align-items: center; 
  justify-content: center;
}
</style>
