<template>
  <v-container fluid>
    <v-row no-gutters class="mb-2">
      <v-col cols="12" class="text-left">
        <b>{{ getLabelViaggio(viaggio) }} {{ viaggio.tipoViaggio }}</b>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="10">
        <v-row no-gutters>
          <!-- {{ viaggio }} -->
          <v-col cols="4" class="text-left">
            <span v-if="viaggio.tipoViaggio === 'ARRIVO'">
              Arrivo previsto: <b>{{ viaggio.eta | formatDateTime }}</b>
            </span>
            <span v-else>
              Partenza prevista: <b>{{ viaggio.etd | formatDateTime }}</b>
            </span>
          </v-col>
          <v-col cols="4" class="text-left">
            <span>
              Stato viaggio: <b>{{ viaggio.statoViaggio }}</b>
            </span>
          </v-col>
          <v-col cols="4" class="text-left">
            <span>
              Carri: <b>{{ viaggio.numeroCarri }}</b>
            </span>
            <span class="ml-2">
              Uti {{viaggio.tipoViaggio === 'ARRIVO'?'Scaricate':'Caricate'}}: <b>{{ viaggio.tipoViaggio === 'ARRIVO'?viaggio.numeroUtiScaricate:viaggio.numeroUtiCaricate }}/{{ viaggio.numeroUti }}</b>
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" class="text-left">
            <span v-if="viaggio.tipoViaggio === 'ARRIVO'">
              Arrivo: <b>{{ viaggio.ata | formatDateTime }}</b>
            </span>
            <span v-else>
              Partenza: <b>{{ viaggio.atd | formatDateTime }}</b>
            </span>
          </v-col>
          <v-col cols="8" class="text-left">
            <span>
              Stato operativo: <b>{{ viaggio.statoOperativo }}</b>
            </span>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="2" class="trip-details">
        <v-btn color="primary" v-if="hasCosmosConnector() && viaggio.tipoViaggio === 'ARRIVO'" :disabled="viaggio.statoOperativo!=='LISTAINPROGRESS'" @click="confirmDialogVisible = true" title="Conferma lista">
          <v-icon>mdi-text-box-check-outline</v-icon>
        </v-btn>      
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmDialogVisible"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="warning">Attenzione</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="text-body-2">
                Confermare la lista treno attuale come definitiva?
              </v-col>
            </v-row>
          </v-container>         
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="confirmDialogVisible = false" outlined>{{tLabel("No")}}</v-btn>
          <v-btn @click="confermaListaTreno(viaggio)" color="success">{{tLabel("Sì")}}</v-btn>         
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import railsMixins from "../rails/mixins/railsMixins";

export default {
  name: "TripInfoPanelFull",
  data() {
    return {
      confirmDialogVisible: false
    };
  },
  mixins: [railsMixins],
  props: {
    viaggio: {},
  },
  mounted() {},
  created() {},
  methods: {
    async confermaListaTreno(viaggio) {
      try {
        await this.$api.trainData.confermaListaTrenoViaggio(viaggio.id);        
        this.confirmDialogVisible = false;
        this.$eventBus.$emit('convoyReload');   
      } catch (e) {
        this.showError("conferma pianificazione non andato a buon fine");
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.info-panel {
  padding: 0px !important;
}
.trip-details {
  float: left;
  vertical-align: middle;
  text-align: center;
}
</style>
