<template>
    <v-btn 
        :title="btn_title_exportCSV" 
        :icon="icona"
        @click="exportCSV"  
    >
    <v-icon color="success" medium>mdi-file-table-outline</v-icon>       
    </v-btn>
</template>

<script>
import ToastMixin from '../../mixins/ToastMixin';

export default {
    name: "csvExporter",
    data() {
        return {
            valid: true,
        };
    },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        separator: {
            type: String,
            default: ','
        },
        icona: {
            type: String,
            default: 'icon'
        }
    },
    mixins: [ToastMixin],
    methods: {
        exportCSV() {
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(this.items[0]).join(";"),
                ...this.items.map(item => Object.values(item).join(";"))
            ]
            .join("\n")
            .replace(/(^\[)|(\]$)/gm, "");
            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "export.csv");
            link.click();
        },
        closeDetailsDialog() {
            this.dialog = false;
            this.formUpdated = false;
            this.$emit('close', this.dialog);
        }
    }
}
</script>