<template>
  <v-container>
    <div class="wagon-ordering-panel">
      <div v-if="carriConvoglio.length===0">Nessun carro associato al convoglio</div>
      <div class="wagon-ordering-panel-container">
        <div v-for="(carroConvoglio, idx) in carriConvoglio" :key="carroConvoglio.id">
          <div >
            <v-btn v-if="idx === 0" title="Inverti ordine carri" style="float: left" @click="reverseOrder" :disabled="!editingEnabled" color="primary">
              <v-icon large>mdi-swap-horizontal</v-icon>
            </v-btn>
            <v-combobox
              :background-color="carroConvoglio.id===highlighted?'yellow accent-1':null"            
              v-model="carroConvoglio.posizione"
              :items="orderItems"
              dense
              @change="onChangeOrder(carroConvoglio)"
              style="width: 50px; font-size: 0.8rem; float: right;"
              :disabled="!editingEnabled"
            />
          </div>
        </div>
      </div>
      <draggable name="UTI" v-model="carriConvoglio" v-bind="dragOptions" class="wagon-group-panel-container" :disabled="!editingEnabled">
        <transition-group type="transition" name="flip-list" class="list-group-panel">
          <div :ref="carroConvoglio.id"  v-for="carroConvoglio in carriConvoglio" :key="carroConvoglio.id" class="list-group-item" @dragend="onDragCarroConvoglio(carroConvoglio.id)">
            <v-card :class="highlighted===carroConvoglio.id?'highlighted':null" >
              <span class="wagon-header">
                {{ getCarroLicensePlate(carroConvoglio) }}
                <v-icon v-if="getCarroLength(carroConvoglio)" style="display: inline; float: right; margin-right: 5px"> mdi-rectangle </v-icon>
                <v-icon v-else style="display: inline; float: right; margin-right: 5px"> mdi-rectangle-outline </v-icon>
              </span>
              <p class="wagon-details">
                Tipo: <b>{{ getCarroType(carroConvoglio) }}</b>
                <br />
                ISO: <b>{{ getCarroIso(carroConvoglio) }}</b> - IMO: <b>{{ getCarroImo(carroConvoglio) }}</b>
                <br />
                <v-icon>mdi-weight</v-icon> {{ getCarroTara(carroConvoglio) }} Kg
                <br />
                <v-icon>mdi-tape-measure</v-icon> {{ getCarroLength(carroConvoglio) }} m
                <br />
                <v-icon>mdi-grid-large</v-icon> posizione: {{ getCarroPosizioneBinario(carroConvoglio) }} m
              </p>
            </v-card>
          </div>
        </transition-group>
      </draggable>    
    </div>

    <v-row class="pt-3">
      <v-col cols="12" class="text-right" md="2" offset-md="10">
        <v-spacer></v-spacer>
        <v-btn outlined @click="convoyWagonOrderCancel" :disabled="cancelDisabled" class="mx-2"> {{ tLabel("Cancel") }} </v-btn>
        <v-btn color="success" @click="convoyWagonOrderUpdate" :disabled="saveDisabled"> {{ tLabel("Save") }} </v-btn>
      </v-col>
    </v-row>

    <!-- {{ showCarriInfo(convoglio.listCarri) }} -->

  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import convoyVisualizationMixins from "../mixins/convoyVisualizationMixins";

export default {
  name: "wagonOrdering",
  components: {
    draggable,
  },
  props: {
    convoglio: {
      type: Object,
      default: () => {},
    },
    editingEnabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dragOptions: {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      },
      saveDisabled: true,
      cancelDisabled: true,
      highlighted: null
    };
  },
  mixins: [convoyVisualizationMixins],
  computed: {
    orderItems() {
      const oItems = [];
      for (let i = 1; i <= this.convoglio.listCarri.filter(c => c.visitaCarro.targa!=='0').length; i++) {
        oItems.push(i);
      }
      return oItems;
    },
    carriConvoglio() {
      for (let i = 1; i <= this.convoglio.listCarri.length; i++) {
        this.convoglio.listCarri[i - 1].posizione = i;
      }
      return this._.orderBy(this.convoglio.listCarri, [(cc) => cc.posizione]).filter(c => c.visitaCarro.targa!=='0');
    },
    carriConvoglio: {
      get: function () {
        return this._.orderBy(this.convoglio.listCarri, [(cc) => cc.posizione]).filter(c => c.visitaCarro.targa!=='0');
      },
      set: async function (newList) {
        var unknownList = this.convoglio.listCarri.filter(c => c.visitaCarro.targa==='0');    
        if (unknownList && unknownList.length>0) {           
          newList.push(unknownList[0]);
        }
        this.convoglio.listCarri = newList;
                   
        for (let i = 1; i <= this.convoglio.listCarri.length; i++) {
          if (this.convoglio.listCarri[i - 1].visitaCarro && this.convoglio.listCarri[i - 1].visitaCarro.targa==="0") {
            this.convoglio.listCarri[i - 1].posizione = -1;
          } else {
            this.convoglio.listCarri[i - 1].posizione = i;
          }        
        }
        this.saveDisabled = false;
        this.cancelDisabled = false;
      },
    },
  },
  methods: {
    onDragCarroConvoglio(carroConvoglioId) {
      this.highlighted = carroConvoglioId;
    },
    onChangeOrder(carroConvoglio) {
      console.log(carroConvoglio.visitaCarro.targa + " - " + carroConvoglio.posizione);
      this.highlighted = carroConvoglio.id;
      for (let i = 1; i <= this.convoglio.listCarri.filter(c => c.visitaCarro.targa!=='0').length; i++) {
        console.log(i);
        if (!this._.find(this.convoglio.listCarri.filter(c => c.visitaCarro.targa!=='0'), (cc) => cc.posizione === i)) {
          this._.find(this.convoglio.listCarri, (cc) => cc !== carroConvoglio && cc.posizione === carroConvoglio.posizione).posizione = i;
        }
      }
      this.convoglio.listCarri = Object.assign([], this.convoglio.listCarri);
      this.saveDisabled = false;
      this.cancelDisabled = false;
    },
    reverseOrder() {
      let pos = 1;
      this._.orderBy(this.convoglio.listCarri, [(cc) => cc.posizione], ["desc"]).forEach((cc) => {
        if (cc.posizione!==-1) cc.posizione = pos++;
      });
      this.saveDisabled = false;
      this.cancelDisabled = false;
    },
    async convoyWagonOrderUpdate() {
      try {
        await this.$api.trainData.updateConvoyData(this.convoglio);
        this.showSuccess("convoglio salvato correttamente");
        this.$eventBus.$emit("convoyReload");
        this.saveDisabled = true;
        this.cancelDisabled = true;
      } catch (e) {
        this.showError("aggiornamento convoglio non andato a buon fine");
        console.log(e);
      }
    },
    convoyWagonOrderCancel() {
      this.$eventBus.$emit("convoyReload");
      this.cancelDisabled = true;
      this.saveDisabled = true;
      this.highlighted = null;
    },
    showCarriInfo(listCarri) {
      var res = new Array();
      listCarri.forEach(c => {
        var carro = {
          targa: c.visitaCarro.targa,
          posizione: c.posizione
        }
        res.push(carro);
      });
      return res;
    }
  },
};
</script>

<style scoped>
.wagon-ordering-panel {
  overflow: auto;
}
.wagon-group-panel-container {
  padding-top: 5px;
}
.wagon-ordering-panel-container > div {
  display: table-cell;
  height: 50px;
}
.wagon-ordering-panel-container > div > div {
  padding-left: 5px;
  width: 155px;
}
.wagon-group-panel-container {
  padding-top: 5px;
  height: 160px;
  background-color: #ffffff;
}
.list-group-panel {
  min-height: 50px;
  display: block;
}
.list-group-item {
  cursor: move;
  padding-left: 5px;
  display: table-cell;
}
.list-group-item > div {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border: 1px solid rgb(90, 90, 89);
  border-style: dashed;
  padding: 3px;
  width: 150px !important;
  height: 125px;
}

.list-group-item > div.highlighted {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  border: 4px solid#FFFF8D !important;
  border-style: double;
  padding: 1px;
  width: 150px !important;
  height: 125px;
}

.wagon-header,
.wagon-title {
  font-size: 0.7rem !important;
  font-weight: bold !important;
}
.wagon-details,
.wagon-details i {
  font-size: 0.7rem !important;
}

/* .highlighted {
  border: 5px solid#effe65 !important;
} */
</style>
