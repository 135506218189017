<template>
  <v-container>
    <v-form ref="form" v-model="valid">
      <v-row no-gutters>
        <v-col cols="5">
          <v-text-field :label="tLabel('Nome convoglio')" v-model="convoglio.nome" :disabled="!editingEnabled" />
        </v-col>
      </v-row>
      
      <v-row no-gutters v-if="showDate">
        <v-col cols="5">
          <v-text-field :label="tLabel('Arrivo previsto')" v-model="convoglio.eta" readonly />
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="5">
          <v-text-field :label="tLabel('Partenza prevista')" v-model="convoglio.etd" readonly />
        </v-col>
      </v-row>
      
      <v-row>
        <v-col sm="6" md="3">
          <v-combobox
            clearable
            v-model="selectedPark"
            :items="parks"
            :label="tLabel('Parco binari')"
            item-text="codice"
            :disabled="!editingEnabled || !parkRailsEditingEnabled || disableBinario(convoglio)"
          />
        </v-col>
      </v-row>
      <v-row v-if="railsPanelVisible">
        <v-col sm="4" md="8">
          <v-radio-group v-model="selectedRail" :disabled="!editingEnabled || !parkRailsEditingEnabled || disableBinario(convoglio)" row>
            <v-radio v-for="rail in rails" :key="rail.id" :value="rail" :label="`B. ${rail.numero}`" />
          </v-radio-group>
        </v-col>
        <v-col sm="4" md="2">
          <v-text-field
            type="number"
            :rules="[validationRules.greaterThanOrEqualToZero]"
            v-model.number="railPosition"
            :label="tLabel('posizioneBinario')"
            :disabled="!editingEnabled || !parkRailsEditingEnabled || disableBinario(convoglio)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-right" md="2" offset-md="10">
          <v-spacer></v-spacer>
          <v-btn color="success" v-if="editingEnabled" @click="saveConvoy" :disabled="saveDisabled"> {{ tLabel("Save") }} </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ToastMixin from "../../mixins/ToastMixin";
import ConvoyVisualizationMixins from "../rails/mixins/convoyVisualizationMixins";
import MultiParkManagementMixins from "../../mixins/MultiParkManagementMixins";
import moment from 'moment';

export default {
  name: "convoyEditPanel",
  components: {},
  data() {
    return {
      valid: true,
      numericRules: [(v) => v === 0 || !!v || "campo obbligatorio", (v) => (!isNaN(v) && +v >= 0) || "valore numerico non valido"],
      requiredRules: [(v) => !!v || "campo obbligatorio"],
      formRender: false,
      rails: [],
      selectedRail: null,
      railPosition: 0,
      convoglioOriginale: null,
      selectedRailOriginale: null,
      railPositionOriginale: 0,
    };
  },
  props: {
    convoglio: {
      type: Object,
      required: true,
    },
    editingEnabled: {
      type: Boolean,
      default: true,
    },
    parkRailsEditingEnabled: {
      type: Boolean,
      default: true,
    },
    showDate: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [ToastMixin, MultiParkManagementMixins, ConvoyVisualizationMixins],
  async mounted() {
    await this.fetchData();
    this.convoglioOriginale = JSON.parse(JSON.stringify(this.convoglio));
    await this.fetchDataOriginale();
  },
  watch: {
    convoglio: async function (newValue) {
      await this.fetchData();
      this.convoglioOriginale = JSON.parse(JSON.stringify(this.convoglio));
      await this.fetchDataOriginale();
    },
    selectedPark: async function (newValue) {
      if (newValue) {
        this.rails = await this.$api.railData.getRailwayData(newValue.codice);
        this.selectedRail = this._.find(this.rails, (r) => this.convoglio.binario && r.id === this.convoglio.binario.id);
        this.railPosition = this.convoglio.posizioneBinario || 0;
      } else {
        this.rails = [];
        this.selectedRail = null;
        this.railPosition = 0;
      }
    },
  },
  methods: {
    disableBinario(convoglio) {
      if(!convoglio) return;
      if (convoglio.stato === "USCITOTERMINAL") return "disabled";
      if (convoglio.stato === "INTERMINAL" && convoglio.binarioId ) return "disabled";
    },

    async fetchData() {
      if (this.convoglio && this.convoglio.binario) {
        var codiceParcoBinari = this.convoglio.binario.codiceParcoBinari;
        if (this.convoglio.binario.parcoBinari) {
          codiceParcoBinari = this.convoglio.binario.parcoBinari.codice;
        }
        if (codiceParcoBinari) {
          this.rails = await this.$api.railData.getRailwayData(codiceParcoBinari);
          this.selectedPark = this._.find(this.parks, (p) => p.codice === codiceParcoBinari);
          this.selectedRail = this._.find(this.rails, (r) => this.convoglio.binario && r.id === this.convoglio.binario.id);
          this.railPosition = this.convoglio.posizioneBinario;
        }
      }
    },
    async fetchDataOriginale() {
      if (this.convoglioOriginale && this.convoglioOriginale.binario) {
        var codiceParcoBinari = this.convoglioOriginale.binario.codiceParcoBinari;
        if (this.convoglioOriginale.binario.parcoBinari) {
          codiceParcoBinari = this.convoglioOriginale.binario.parcoBinari.codice;
        }
        if (codiceParcoBinari) {
          let railsOriginale = await this.$api.railData.getRailwayData(codiceParcoBinari);
          this.selectedParkOriginale = this._.find(this.parks, (p) => p.codice === codiceParcoBinari);
          this.selectedRailOriginale = this._.find(railsOriginale, (r) => this.convoglioOriginale.binario && r.id === this.convoglioOriginale.binario.id);
          this.railPositionOriginale = this.convoglioOriginale.posizioneBinario;
        }
      }
    },
    async saveConvoy() {
      try {
        if (this.$refs.form.validate()) {
          const req={
            id: this.convoglio.id,
            binarioId: this.convoglio.binarioId==null ? this.selectedRail.id : null, //assegma bomario solo se convoglio non lo aveva
            posizioneBinario: this.railPosition,
            dataOperazione: null,
            nomeConvoglio: this.convoglio.nome,
          };
          await this.$api.trainData.updateConvoyPlanning(req);
          this.showSuccess("convoglio salvato correttamente");
        } else {
          this.showError("dati del convoglio non corretti");
        }
      } catch (error) {
        console.log("Errore saveConvoy", error);
        let message = "aggiornamento convoglio non andato a buon fine";
        if (error.data) {
          message = error.data.message;
        }
        if (message.startsWith("javax.ws.rs.BadRequestException: ")) message = message.substring("javax.ws.rs.BadRequestException: ".length);
        this.showError(message);
      } finally {
        this.$eventBus.$emit("convoyReload");
      }
    },
  },
  computed: {
    railsPanelVisible() {
      return this.rails && this.rails.length;
    },
    saveDisabled() {
      if (this.convoglio && this.convoglioOriginale) {
        if (this.convoglio.nome !== this.convoglioOriginale.nome) {
          return false;
        }

        if ((!this.selectedPark && this.selectedParkOriginale) || (this.selectedPark && !this.selectedParkOriginale)) {
          return false;
        }

        if (this.selectedPark && this.selectedParkOriginale && this.selectedPark.codice !== this.selectedParkOriginale.codice) {
          return false;
        }

        if ((!this.selectedRail && this.selectedRailOriginale) || (this.selectedRail && !this.selectedRailOriginale)) {
          return false;
        }

        if (this.selectedRail && this.selectedParkOriginale && this.selectedRail.id !== this.selectedRailOriginale.id) {
          return false;
        }

        if (this.railPosition !== this.railPositionOriginale) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style>
.rm-scroll-cal .v-calendar-daily__scroll-area {
  overflow-y: auto !important;
}
.v-time-picker-title {
  color: #000000 !important;
}
.v-date-picker-title__date {
  opacity: 0.5 !important;
}
.v-time-picker-title__time {
  opacity: 0.5 !important;
}
.v-picker__title__btn {
  opacity: 0.5 !important;
}
</style>
